<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Activité" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une activité</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le métier -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">métier</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner un métier"
                    :items="metiers"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucun métier"
                    v-model="metierId"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    nom digital
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom complet -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">
                    nom complet
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="fullName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Libellé -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">libellé</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le libellé"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Slug -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">slug</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le Slug"
                    dense
                    v-model="slug"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les sous-activités associées
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des sous-activités à associé -->
                  <div v-for="(sub, i) in associateSubActivities" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ sub.digitalName }}
                      </div>

                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateSubActivity(sub)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des sous-activités associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedSubActivity"
                        :items="availableSubActivities"
                        item-text="digitalName"
                        return-object
                        placeholder="Associer une sous-activité"
                        hint="Associer une sous-activité puis appuyer sur le bouton '+'"
                        clearable
                        v-on:keyup.enter="addSubActivity(selectedSubActivity)"
                        no-data-text="aucune sous-activité"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addSubActivity(selectedSubActivity)"
                        :disabled="!selectedSubActivity"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import { trimString } from "@/tools/string_tool.js";

export default {
  name: "AddActivity",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceActivities: null,
      serviceMetiers: null,
      serviceSubActivities: null,

      /**La donnée originale */
      source: null,
      /**le nom */
      name: null,
      /**identifiant du métier sélectionné */
      metierId: null,
      /** le nom digital de l'activité */
      digitalName: null,
      /** Le nom complet de l'activité */
      fullName: null,
      /** le libellé de l'activité */
      label: null,
      /** le slug de l'activité */
      slug: null,

      /**les sous-activités associées */
      associateSubActivities: [],

      /** toutes les sous-activités (référence) */
      allSubActivities: [],

      /**la sous-activité sélectionnée pour l'ajout */
      selectedSubActivity: null,

      /** les métiers disponibles pour l'activité */
      metiers: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de tous les métiers
        this.metiers = await this.serviceMetiers.getAll();
        // Récupération de toutes les sous-activités
        this.allSubActivities = await this.serviceSubActivities.getAllSubActivities();

        this.associateSubActivities = [];
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /**retirer une sous_activité associée */
    removeAssociateSubActivity(subActivity) {
      this.associateSubActivities.splice(
        this.associateSubActivities.findIndex((r) => r.id == subActivity.id),
        1
      );
    },
    /**Ajouter une sous-activité associée */
    addSubActivity(subActivity) {
      if (!subActivity) return;

      this.associateSubActivities.push(subActivity);
      this.selectedSubActivity = null;
    },

    async save() {
      let success = true;

      let act = null;

      // enregistrement de l'activité
      if (this.activityHasChanged) {
        try {
          let entity = {};
          entity.name = this.name;
          entity.metier = this.metierId;
          entity.digitalName = this.digitalName;
          entity.fullName = this.fullName;
          entity.label = this.label;
          entity.slug = this.slug;

          act = await this.serviceActivities.createActivity(entity);
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de l'activité : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }

      // enregistrement de la partie sous-activités
      if (this.subActivitiesHaveChanged && success) {
        //les sous-activités à ajouter
        try {
          //on ajoute les sous-activités à l'activité'
          for (let i = 0; i < this.associateSubActivities.length; i++) {
            await this.serviceActivities.addSubActivityToActivity(
              act.id,
              this.associateSubActivities[i].id
            );
          }
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement des sous-activités : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }

      if (success) {
        this.$router.go(-1);
      }
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.name || this.name == "") {
          return false;
        }

        if (!this.metierId || this.metierId == "") {
          return false;
        }

        let changed = false;

        if (this.activityHasChanged) {
          changed = true;
        }

        if (this.subActivitiesHaveChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    availableSubActivities() {
      let availables = [];

      let ids = this.associateSubActivities.map((r) => r.id);

      availables = this.allSubActivities.filter((r) => !ids.includes(r.id));

      return availables;
    },

    /**retourne true si l'activité est modifiée (ne concerne pas les sous-activités: voir subActivitiesHaveChanged)  */
    activityHasChanged() {
      let changed = false;

      if (this.name) {
        changed = true;
      }

      if (this.metierId) {
        changed = true;
      }

      if (this.digitalName) {
        changed = true;
      }

      if (this.fullName) {
        changed = true;
      }

      if (this.label) {
        changed = true;
      }

      if (this.slug) {
        changed = true;
      }

      return changed;
    },
    /** retourne true si les sous-activités ont changés */
    subActivitiesHaveChanged() {
      return this.associateSubActivities.length > 0;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());
    this.serviceSubActivities = new SubActivitiesService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>